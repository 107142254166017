import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { OperatingCountry, PricingSubscriptionPlan } from '@cohiretech/common-types';

import { CurrentPlanDetails, PricingCountry, PricingVer } from 'types';
import { useDarkMode } from 'hooks/useDarkMode';
import { useShowReactivationModal } from 'store';
import { useSearchParams } from 'hooks/useSearchParams';

import PricingBottomSections from 'components/pricingbottomsections';
import Tabs from 'components/tabs';

import PricingPlansSection from 'v2/components/ui/organisms/PricingPlansSection';
import FeatureComparisonTable from 'v2/components/ui/templates/Pricing/components/FeatureComparisonTable';
import { BoldText, DiscountBlock } from 'v2/components/ui/styles';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import SanFranciscoPricingBanner from '../../organisms/SanFranciscoPricingBanner/SanFranciscoPricingBanner';

import {
  DISCOUNT_BLOCK_PROPS,
  getPricingType,
  PAY_PER_HIRE_TABS,
  PricingTabs
} from './Pricing.helpers';

export type PricingProps = {
  pricingVer: PricingVer;
  title?: string;
  subtitle?: string;
  pricingCountry?: PricingCountry;
  isPopup?: boolean;
  currentPlanDetails?: CurrentPlanDetails;
  availablePlans?: PricingSubscriptionPlan[];
  showStorySection?: boolean;
  tabsInURL?: boolean;
  pricingPortal?: boolean;
  className?: string;
};

export default function Pricing({
  pricingVer,
  title,
  subtitle,
  pricingCountry,
  isPopup,
  currentPlanDetails,
  availablePlans,
  showStorySection,
  tabsInURL = true,
  pricingPortal,
  className
}: PricingProps) {
  const location = useLocation();
  const darkMode = useDarkMode();
  const [showReactivationModal] = useShowReactivationModal();

  const [tab, setTab] = useState<PricingTabs>('unlimited');
  const { searchParams, updateSearchParams } = useSearchParams();

  const onTabChange = (newTab: PricingTabs) => {
    setTab(newTab);
    if (!tabsInURL) return;

    updateSearchParams({ plan: newTab });
  };

  const pricingType = pricingCountry || getPricingType(location?.pathname);
  const { button, ...discountBlockProps } = DISCOUNT_BLOCK_PROPS[pricingVer];
  const buttonStyle = darkMode ? 'stroke_white' : 'stroke_dark_blue';
  const showPayPerHire = false; // getShowPayPerHire(currentPlanDetails, pricingType);
  const isPayPerHireTabActive = showPayPerHire && tab === 'payperhire';
  const isUSPricingV5 = pricingVer === 5 && pricingType === OperatingCountry.US;
  const hidePrice = !pricingPortal && !isUSPricingV5;

  useEffect(() => {
    if (!tabsInURL) return;
    if (!searchParams.plan) {
      updateSearchParams({ plan: tab });
      return;
    }

    const activeTab = PAY_PER_HIRE_TABS.map(({ value }) => value).includes(searchParams.plan)
      ? searchParams.plan
      : 'unlimited';
    setTab(activeTab as PricingTabs);
  }, []);

  return (
    <>
      <PricingTemplate
        id="new_plans"
        className={`left_alignment ${className}`}
        showReactivationModal={showReactivationModal}
      >
        <PricingTopSection largeGap={showPayPerHire}>
          <PageHeadings className="center_alignment">
            <SanFranciscoPricingBanner show={isUSPricingV5} spacing="05" />
            {title && <h1 className="heading">{title}</h1>}
            <ConditionalRender
              predicate={showPayPerHire}
              fallback={
                <h2 className={isPopup ? 'pop_up_sub_title' : 'subheading'}>
                  {subtitle || 'No hiring fees. No hidden costs.'}
                </h2>
              }
            >
              <Tabs
                tabs={PAY_PER_HIRE_TABS}
                onChange={({ value }) => {
                  onTabChange(value as PricingTabs);
                }}
                size="large"
                current={tab}
                tabStyle="default"
                appearance="underlined"
              />
            </ConditionalRender>
          </PageHeadings>
          <PricingPlansSection
            pricingVer={pricingVer}
            pricingCountry={pricingType}
            currentPlanDetails={currentPlanDetails}
            availablePlans={availablePlans}
            isPayPerHire={isPayPerHireTabActive}
            hidePrice={hidePrice}
          />
          <ConditionalRender predicate={isUSPricingV5}>
            <LinkToUKPricing />
          </ConditionalRender>
          <ConditionalRender predicate={hidePrice && !isPayPerHireTabActive}>
            <BookACallLink />
          </ConditionalRender>
          <ConditionalRender predicate={!isPayPerHireTabActive && !isUSPricingV5}>
            <DiscountBlock
              notificationBox
              button={{ ...button, buttonStyle }}
              {...discountBlockProps}
            />
          </ConditionalRender>
        </PricingTopSection>
        <ConditionalRender predicate={(showPayPerHire && tab === 'unlimited') || !showPayPerHire}>
          <FeatureComparisonTable
            pricingCountry={pricingType}
            currentPlan={currentPlanDetails?.plan}
            pricingVer={pricingVer}
            tickColour="cordGradient"
          />
        </ConditionalRender>
      </PricingTemplate>
      <PricingBottomSections
        showVideoSection
        showStorySection={showStorySection}
        pricingCountry={pricingType}
        pricingVer={pricingVer}
        isPayPerHire={isPayPerHireTabActive}
      />
    </>
  );
}

export const LinkToUKPricing = () => {
  return (
    <TextWithLinkWrapper>
      Are you a UK company looking to use cord?
      <Link to="/uk/pricing" className="lightblue_link">
        View UK pricing
      </Link>
    </TextWithLinkWrapper>
  );
};

const BookACallLink = () => {
  return (
    <TextWithLinkWrapper>
      <BoldText>Have a question?</BoldText>
      <Link to="/book-a-call" className="lightblue_link">
        Book a call
      </Link>
      with a product expert
    </TextWithLinkWrapper>
  );
};

const TextWithLinkWrapper = styled.span`
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  .lightblue_link {
    font-weight: ${({ theme: { typography } }) => typography.regular};
  }
`;

const PricingTemplate = styled.div<{ showReactivationModal: boolean }>`
  max-width: ${({ theme: { grid } }) => grid.pricingpagewidth};
  margin: auto;

  ${({ showReactivationModal }) =>
    !showReactivationModal &&
    css`
      padding: 4rem 1.25rem 3rem;
    `}

  .button.fill_cord_gradient {
    padding: 0.75rem 1.25rem;
  }
`;

const PricingTopSection = styled.div<{ largeGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ largeGap }) => (largeGap ? '4rem' : '2.25rem')};
  ${({ largeGap, theme: { media } }) => media.mobile`
    gap: ${largeGap ? '3rem' : '1.5rem'};
  `};
`;

const PageHeadings = styled.div`
  h1.heading {
    font-size: 4rem;

    ${({ theme: { media } }) => media.smallMobile`
       font-size: 3rem;
    `}
  }

  .tabs_wrapper {
    margin-top: var(--spacing-03);

    .tab_item {
      ${({ theme: { media } }) => media.mobile`
      font-size: var(--type-md-lg);
    `}
    }
  }

  .subheading {
    font-size: 26px;
    margin: 0.25rem auto 0.75rem;

    ${({ theme: { media } }) => media.mobile`
      margin-bottom: 0;
    `}
  }

  .icon {
    font-weight: bold;
    margin-right: 0;
  }
`;
